<template>
    <div>
        <div class="headers">
            <img src="../assets/images/ailogo.png"/>
            <h2>加入公司 开始招聘</h2>
        </div>
        <div class="box">
            <div class="step">
                <span class="stepTip">开启高效招聘，只需以下三步：</span>
                <el-steps :active="activeStep" align-center>
                    <el-step title="完善账户信息"></el-step>
                    <el-step title="完善公司信息"></el-step>
                    <el-step title="完善联系人信息"></el-step>
                </el-steps>
            </div>
            <div class="centerBox">
                <div class="leftSide">
                    <img src="../assets/images/guide-profile.png"/>
                    <p>完善资料，开启高效招聘</p>
                </div>
                <div class="rightSide">
                    <!-- 第一步 -->
                    <el-form v-show="activeStep==0" :model="OneStepForm" :rules="Onerules" 
                    ref="OneStepForm" label-width="100px" class="demo-ruleForm" label-position="right">
                        <el-form-item label="账户：" prop="phoneNum">
                            <el-input clearable v-model="OneStepForm.phoneNum" placeholder="请输入您的手机号作为登录账户"></el-input>
                        </el-form-item>
                        <el-form-item label="昵称：" prop="userName">
                            <el-input clearable v-model="OneStepForm.userName" placeholder="请输入您的昵称"></el-input>
                        </el-form-item>
                        <el-form-item label="密码：" prop="password">
                            <el-input clearable type="password" v-model="OneStepForm.password" 
                            placeholder="请为您的账户设置密码（不少于6位数）"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码：" prop="surepwd">
                            <el-input clearable type="password" v-model="OneStepForm.surepwd" placeholder="请再次确认密码"></el-input>
                        </el-form-item>
                        <div class="btnBox">
                            <button type="button" class="btns" @click="submitOneStepForm('OneStepForm')">下一步</button>  
                        </div>
                    </el-form>
                    <!-- 第二步 -->
                    <el-form   v-show="activeStep==1" :model="ruleForm" :rules="rules"
                     ref="ruleForm" label-width="120px" class="demo-ruleForm" label-position="right">
                        <el-form-item label="公司名称：" prop="companyName">
                            <el-input clearable v-model="ruleForm.companyName" placeholder="请输入公司名称"></el-input>
                        </el-form-item>
                        <el-form-item label="组织机构代码：" prop="orgCode">
                            <el-input clearable v-model="ruleForm.orgCode" placeholder="请输入公司组织机构代码"></el-input>
                        </el-form-item>
                        <el-form-item label="行业类别：" prop="companyTradeId">
                            <el-select clearable v-model="ruleForm.companyTradeId" placeholder="请选择公司所在行业类别">
                            <el-option v-for="(v,i) in companyTradeList" :key="i" 
                            :label="v.companyTrade" :value="v.id"></el-option>
                            
                            </el-select>
                        </el-form-item>
                        <el-form-item  label="公司规模：" prop="companyScale">
                            <el-select clearable v-model="ruleForm.companyScale" placeholder="请选择公司规模">
                            <el-option 
                                v-for="(v,i) in companyScaleList" :key=" i"
                                :label="v" :value="v">
                            </el-option>                
                                
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所在区域：" prop="companyProviceAndCity">
                            <el-cascader
                                clearable
                                :options="options"
                                v-model="ruleForm.companyProviceAndCity"
                                @change="handleChange"
                                placeholder="请选择公司所在区域">
                            </el-cascader>
                        </el-form-item>
                       <el-form-item label="详细地址：" prop="companyAddress">
                            <el-input clearable v-model="ruleForm.companyAddress" placeholder="请输入公司地址"></el-input>
                        </el-form-item>
                        <el-form-item label="公司网站：" prop="companyWebsite">
                            <el-input clearable v-model="ruleForm.companyWebsite" placeholder="请输入公司官网地址"></el-input>
                        </el-form-item>
                         <el-form-item label="公司简介：" prop="companyIntroduce">
                            <el-input clearable type="textarea" v-model="ruleForm.companyIntroduce" placeholder="请简单介绍公司"></el-input>
                        </el-form-item>
                        
                        <div class="btnBox">
                            <button type="button" class="btns" @click="beforeStep()">上一步</button>
                            <button type="button" class="btns" @click="submitForm('ruleForm')">下一步</button>  
                        </div>
                    </el-form>
                    <!-- 第三步 -->
                    <el-form v-show="activeStep==2||activeStep==3" :model="ThreeStepForm" :rules="Threerules" 
                    ref="ThreeStepForm" label-width="100px" class="demo-ruleForm" label-position="right">
                        
                       <el-form-item label="联系人：" prop="contactsName">
                            <el-input clearable v-model="ThreeStepForm.contactsName" placeholder="请输入企业联系人姓名"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="联系电话：" prop="linkPhoneNum">
                            <el-input clearable v-model="ThreeStepForm.linkPhoneNum" placeholder="请输入招聘联系电话"></el-input>
                        </el-form-item> -->
                        <el-form-item label="对接人：" prop="linkTeacher">
                            <el-select clearable v-model="ThreeStepForm.linkTeacher" placeholder="请选择美和易思对接人">
                            <el-option v-for="(v,i) in linkTeaList" :key="i" 
                            :label="v.name" :value="v.id"></el-option>
                            
                            </el-select>
                        </el-form-item>
                        <div class="btnBox">
                            <button type="button" class="btns" @click="()=>{activeStep=1}">上一步</button>
                            <button type="button" class="btns" @click="submitThreeStepForm('ThreeStepForm')">保存 立即去招聘</button>  
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { provinceAndCityData,CodeToText } from 'element-china-area-data'
import {companyScaleList} from '../service/config'
export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
            callback(new Error('请再次输入密码'));
            } else if (value !== this.OneStepForm.password) {
            callback(new Error('两次输入密码不一致!'));
            } else {
            callback();
            }
        };
        var iphoneReg=/^[1]([3-9])[0-9]{9}$/
        var validateIphone = (rule, value, callback) => {
            if (value === '') {
            callback(new Error('请输入您的手机号'));
            } else if (!iphoneReg.test(value)) {
            callback(new Error('请输入正确的手机号'));
            } else {
            callback();
            }
        };
        return {
            companyScaleList,
            linkTeaList:[],
            companyTradeList:[],
            options: provinceAndCityData,
            activeStep:0,
            OneStepForm:{ 
                phoneNum:'',    
                "userName":"",  //账户展示用的名字
                "password":"", 
                surepwd:''
            },
            ThreeStepForm:{
                "contactsName":"", //联系人
                "linkTeacher":'', //对接老师id
            },
            ruleForm: {
                companyName: '',
                orgCode:'',
                companyTradeId: '',
                companyScale:'',
                companyAddress:'',


                
                companyWebsite:'',
                companyIntroduce:'',
                companyProviceAndCity:[]
            },
            Onerules:{
                phoneNum:[{ validator: validateIphone, trigger: 'blur',required: true, }],
                userName:[{ required: true, message: '请输入您的姓名', trigger: 'blur' },],
                password:[{ required: true, message: '请为您的账户设置密码（不少于6位数）', trigger: 'blur' },
                { min: 6, max: 20, message: '密码长度不得少于6位数', trigger: 'blur' }],
                surepwd:[{validator: validatePass, trigger: 'blur' }]
            },
            Threerules:{
                contactsName: [{ required: true, message: '请输入招聘联系人姓名', trigger: 'blur' },],
                linkTeacher:[{ required: true, message: '请选择美和易思对接人', trigger: 'blur' },],
            },
            rules: {
                companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' },],
                orgCode:[{ required: true, message: '请输入公司组织机构代码', trigger: 'blur' },],
                companyTradeId: [
                    { required: true, message: '请选择公司所在行业类别', trigger: 'change' }
                ],
                companyScale:[{ required: true, message: '请选择公司规模', trigger: 'blur' },],
                companyAddress:[{ required: true, message: '请输入公司地址', trigger: 'blur' },],
                companyIntroduce:[{ required: true, message: '请简单介绍公司', trigger: 'blur' },],
                companyProviceAndCity:[{ required: true, message: '请选择公司所在区域', trigger: 'blur' },]
            }
        }
    },
    methods: {
        handleChange(){
            
        },
        beforeStep(){
             this.activeStep=0
        },
        //第三步
        submitThreeStepForm(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.activeStep=3
                    let obj={...this.ThreeStepForm,...this.ruleForm,...this.OneStepForm} 
                    obj.province=CodeToText[obj.companyProviceAndCity[0]]
                    obj.city=CodeToText[obj.companyProviceAndCity[1]]
                    delete obj.companyProviceAndCity
                    this.$htp.post_('/hr/company/add',obj).then(res=>{                     
                        if(res.code==0){
                            // sessionStorage.setItem('token',res.data.phoneNum)
                            // sessionStorage.setItem('id',res.data.id)
                            // sessionStorage.setItem('nickName',res.data.userName) 
                            this.$router.push({path:"/login"})
                        }      
                    })
                } else {
                    return false;
                }
            });
        },
        //第一步
        submitOneStepForm(formName){ 
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.activeStep=1
                } else {
                    return false;
                }
            });
      },
      //第二步
      submitForm(formName) {  
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.activeStep=2
          } else {
            return false;
          }
        });
      },
      getlinkTeaList(){
        this.$htp.get_('/hr/linkTea/list').then(res=>{
            this.linkTeaList=res.data
        })
      },
      getCompanyTradeList(){
        this.$htp.get_('/hr/company/trade/list').then(res=>{
            this.companyTradeList=res.data
        })
      }  
    },
    mounted(){
        this.getlinkTeaList()
        this.getCompanyTradeList()
    }
}
</script>
<style scoped lang='scss'>
@import '../assets/css/register.scss'; 
</style>
<style>
.centerBox .rightSide .el-input__inner{
    border-radius: 0px;   
}
</style>